




/* Breakpoints
========================================================================== */
        $breakpoint_laptop: 1440px;
        $breakpoint_laptop_small: 1280px;
        $breakpoint_tablet: 1200px;
        $breakpoint_tablet_middle: 980px;
        $breakpoint_mobile: 670px;
        $breakpoint_mobile_small: 480px;
        $breakpoint_mobile_thin: 370px;
        $max-breakpoint: 1920;

        $breakpoints: (
                l: $breakpoint_laptop,
                l-s: $breakpoint_laptop_small,
                t: $breakpoint_tablet,
                t-m: $breakpoint_tablet_middle,
                m: $breakpoint_mobile,
                m-s: $breakpoint_mobile_small,
                m-t: $breakpoint_mobile_thin
        );

$media-expressions: (
                'landscape': '(orientation: landscape)',
                'portrait': '(orientation: portrait)',
                'mouse': '(pointer: fine)'
        );

