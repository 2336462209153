/* Breakpoints
========================================================================== */
/* assets/css/app.css */
body {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  background-color: #181818;
  color: #d2d2d2; }
  body p {
    margin: 0.2rem;
    padding: 0.2rem; }
  body h1, body h2, body h3, body h4, body h5 {
    padding: 0;
    margin: 0.2rem;
    font-weight: 300; }
  body h1 {
    font-size: 2.0rem;
    color: #1e6f6d;
    margin: 0.4rem; }
    @media (max-width: 670px) {
      body h1 {
        font-size: 1.5rem; } }
  body h2 {
    font-size: 1.4rem;
    color: #734661; }
    @media (max-width: 670px) {
      body h2 {
        font-size: 1.0rem; } }

.logo {
  margin: 1rem; }
  .logo img {
    max-width: 240px;
    height: auto; }
    @media (max-width: 1200px) {
      .logo img {
        max-width: 180px; } }

.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center; }
  .page-header--content {
    text-align: center;
    margin-top: 4rem; }
    @media (max-width: 670px) {
      .page-header--content {
        margin-top: 2rem; } }
    .page-header--content h1, .page-header--content h2 {
      text-align: center; }
    .page-header--content .btn {
      margin-top: 2rem;
      display: inline-block;
      padding: 0.6rem;
      min-width: 7rem; }

.page-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  min-height: 100vh; }
  .page-content__box {
    background-color: #171717;
    margin: 0.4rem;
    padding: 1rem;
    text-align: center;
    width: 300px; }
    .page-content__box--wrapper {
      display: flex;
      max-width: 700px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: normal; }
      @media (max-width: 670px) {
        .page-content__box--wrapper {
          max-width: 100vw; } }

.btn {
  color: #d2d2d2;
  padding: 1rem 0.6rem;
  background-color: #1e6f6d;
  display: block;
  text-decoration: none;
  min-width: 10rem;
  text-align: center; }
  .btn:hover {
    background-color: #734661; }

.tuille-logos__list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 2rem 0;
  padding: 0; }

.tuille-logos__item {
  width: 200px;
  height: 85px;
  margin: 4px; }
  .tuille-logos__item img {
    display: block;
    max-width: 100%;
    height: auto; }
